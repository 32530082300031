$(function () {
    // ページトップ
    $('.c-pagetop').on('click',function(){
        $('html,body').animate({'scrollTop':'0'},300);
    });

    // スマホメニュー開閉
    $('.trigger_drawer').on('click',function(){
        event.preventDefault();
        var btn = $(this);
        var drawer = $('.l-drawer');
        if(!btn.hasClass('opened')){
            btn.addClass('opened');
            drawer.addClass('opened').fadeIn();
        } else {
            btn.removeClass('opened');
            drawer.removeClass('opened').fadeOut();
        }
    });

    // PC子メニュー開閉
    $('.trigger_menu_child').on('mouseenter',function(){
        event.preventDefault();
        var arrow = $(this).find('.arrow');
        var menu_child = $(this).children('.target_menu_child');
        arrow.addClass('opened');
        menu_child.stop().slideToggle();
    });
    $('.trigger_menu_child').on('mouseleave',function(){
        event.preventDefault();
        var arrow = $(this).find('.arrow');
        var menu_child = $(this).children('.target_menu_child');
        arrow.removeClass('opened');
        menu_child.stop().slideToggle();
    });

    // スマホ子メニュー開閉
    $('.trigger_drawer_child').on('click',function(){
        event.preventDefault();
        var arrow = $(this).children('.arrow');
        var menu_child = $(this).next('.target_drawer_child');
        if(!arrow.hasClass('opened')){
            arrow.addClass('opened');
            menu_child.slideToggle();
        } else {
            arrow.removeClass('opened');
            menu_child.slideToggle();
        }
    });

    // サイドバー子メニュー開閉
    $('.target_left03_acc').css('display','none');
    $('.trigger_left03_acc').on('click',function(){
        event.preventDefault();
        var arrow = $(this);
        var menu_child = $(this).next('.target_left03_acc');
        if(!arrow.hasClass('opened')){
            arrow.addClass('opened');
            menu_child.slideToggle();
        } else {
            arrow.removeClass('opened');
            menu_child.slideToggle();
        }
    });
});